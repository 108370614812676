<template>
  <div id="app">
    <div class="options-container">
      <div class="fields-container">
        <div class="instructions">
          <div class="number">
            1
          </div>
          <div class="label">
            Customize your signature
          </div>
        </div>
        <label for="name">Name</label>
        <input type="text" id="name" v-model="name" />

        <label for="title">Title</label>
        <input type="text" id="title" v-model="title" />

        <label for="email">Email</label>
        <input type="text" id="email" v-model="email" />

        <label for="phone">Phone</label>
        <input type="text" id="phone" v-model="phone" />

        <label for="linkedin">LinkedIn Profile Link</label>
        <input type="text" id="linkedin" v-model="linkedInProfile" />
      </div>
      <div class="images-container">
        <div class="instructions">
          <div class="number">
            2
          </div>
          <div class="label">
            Select your Image
          </div>
        </div>
        <img
          class="choose-image"
          :key="image"
          v-for="(image, index) in images"
          :src="`${imageUrlBase}/${image}`"
          alt=""
          @click="currentImage = index"
        />
      </div>
    </div>
    <div class="signature-container">
      <div class="instructions">
        <div class="number">3</div>
        <div class="label">
          Select, copy and paste your signature
        </div>
      </div>
      <div @click="copy" class="button" id="copy-button">
        <span class="text-1">
          Copy Signature to Clipboard
        </span>
        <span class="text-2">
          Copied 👍
        </span>
      </div>
      <div class="signature" v-html="signature" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  methods: {
    copy() {
      window.getSelection().removeAllRanges();
      let range = document.createRange();
      range.selectNode(document.getElementById('signature'));
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();

      const button = document.getElementById('copy-button');
      button.classList.add('animate');
      setTimeout(() => {
        button.classList.remove('animate');
      }, 600);
    }
  },
  data() {
    return {
      name: 'Fabienne Heer',
      title: 'CEO',
      email: 'fabienne.heer@cleverclip.ch',
      phone: '+41 ',
      linkedInProfile: 'https://www.linkedin.com/in/rahel-heer-09a660ba/',
      websiteLabel: 'cleverclip.ch',
      websiteLocation: 'https://www.cleverclip.ch/',
      address: 'Weihergasse 11<br>3005 Bern',
      rowStyle: 'margin-bottom: 6px; line-height: 1',
      linkStyle: 'color: #4A4647 !important',
      images: [
        'cc-logo.png',
        'Kali.png',
        'Alain.png',
        'Carlo.png',
        'Doris.png',
        'Ely.png',
        'Fabienne.png',
        'Matthias.png',
        'Monique.png',
        'Rahel.png',
        'Ruzci.png',
        'Saimen.png',
        'Sebastian.png',
        'Serena.png',
        'Tanja.png',
        'Thesi.png'
      ],
      currentImage: 0,
      imageUrlBase: `${location.origin}/people`
    };
  },
  computed: {
    signature() {
      return `<table id="signature" style="background: none; font-family: Verdana; font-size: 12px; color: #4A4647;" cellspacing="0">
                <tr>
                    <td style="padding-bottom: 20px">---</td>
                </tr>
                <tr>
                  <td style="padding-right: 30px; vertical-align: middle;">
                    <img width="160" src="${this.imageUrlBase}/${
        this.images[this.currentImage]
      }" alt="">
                  </td>
                  <td style="vertical-align: middle;">
                    <h3 style="${
                      this.rowStyle
                    }; font-weight: bold; font-size: 20px; color: #000; margin-bottom: 6px">
                      ${this.name}
                      ${
                        this.linkedInProfile
                          ? `<a target="_blank" rel="noopener" href="${this.linkedInProfile}" style="margin-left: 3px; position: relative; top: -5px;">
        <img style="margin-bottom: -2px" width="20" src="${this.imageUrlBase}/linkedin.png" alt="">
      </a>`
                          : ''
                      }
                    </h3>
                    <h4 style="font-size: 16px; font-weight: normal; margin: 0;">${
                      this.title
                    }</h4>
                    <p style="line-height: 1.5;">
                        <a style="${this.linkStyle}" href="mailto: ${
        this.email
      }">${this.email}</a><br>
                        <a style="${this.linkStyle}" href="tel: ${
        this.phone
      }">${this.phone}</a>
                    </p>
                  </td>
                </tr>
                <tr>
                    <td style="padding-top: 60px; padding-left: 13px">
                        <a style="${this.linkStyle}" href="${this.websiteLocation}" target="_blank" rel="noopener" title="cleverclip.ch Website">
                          <img style="display: block; margin-left: -5px;" width="160" src="cc-logo.png" alt="Cleverclip Logo">
                        </a>
                        <p><a style="${
                          this.linkStyle
                        };" target="_blank" rel="noopener" href="${this.websiteLocation}">
                            ${this.websiteLabel}
                        </a></p>
                        <p><a style="${
                          this.linkStyle
                        }; text-decoration: none;" target="_blank" rel="noopener" href="https://g.page/Cleverclip?share">
                            ${this.address}
                        </a></p>
                        <p><a style="${
                          this.linkStyle
                        }" href="tel: +41 44 586 94 25">+41 44 586 94 25</a></p>
                        <p><a style="${
                          this.linkStyle
                        }" href="mailto: info@cleverclip.ch">info@cleverclip.ch</a></p>
                        <p style="margin-top: 20px; margin-left: -4px;">
                            <a target="_blank" style="margin-right: 10px; text-decoration: none !important;" rel="noopener" href="https://www.linkedin.com/company/cleverclip/">
                                <img style="padding: 2px; background: #fff; border-radius: 3px;" width="25" src="${
                                  this.imageUrlBase
                                }/linkedin.png" alt="">
                            </a>
                            <a target="_blank" style="margin-right: 10px; text-decoration: none !important;" rel="noopener" href="https://www.instagram.com/cleverclip/">
                                <img style="padding: 2px; background: #fff; border-radius: 3px;" width="25" src="${
                                  this.imageUrlBase
                                }/instagram.png" alt="">
                            </a>
                            <a target="_blank" style="margin-right: 10px; text-decoration: none !important;" rel="noopener" href="https://www.facebook.com/cleverclip.ch">
                                <img style="padding: 2px; background: #fff; border-radius: 3px;" width="25" src="${
                                  this.imageUrlBase
                                }/facebook.png" alt="">
                            </a>
                            <a target="_blank" style="margin-right: 10px; text-decoration: none !important;" rel="noopener" href="https://vimeo.com/cleverclip">
                                <img style="padding: 2px; background: #fff; border-radius: 3px;" width="25" src="${
                                  this.imageUrlBase
                                }/vimeo.png" alt="">
                            </a>
                        </p>
                    </td>

                </tr>
                <tr>
                <td style="padding-top: 30px; padding-left: 13px">
                <a target="_blank" style="margin-right: 10px; text-decoration: none !important; color: #000; background: #E4DE50; padding: 7px 12px; border-radius: 50px; white-space: nowrap;"
                rel="noopener" href="http://eepurl.com/brB6Of">
                  <span style="font-size: 16px; margin-right: 6px; position: relative; top: 1px;">✉</span>️ Subscribe to our Newsletter
                </a>
</td>
</tr>
 </table>
   `;
    }
  }
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 1rem;
  background: #f6f3e4;
}

* {
  font-family: Verdana;
}

label,
input {
  display: block;
}

input {
  margin-bottom: 1rem;
  width: 300px;
}

.signature-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
  padding-top: 4.5rem;
}

.signature {
  background: #fff;
  padding: 1.5rem 3rem;
}

.instructions {
  position: absolute;
  top: 0rem;
  left: 0rem;
  display: flex;
  align-items: center;
}

.button {
  padding: 1rem 2rem;
  background: #000;
  border-radius: 200px;
  color: #fff;
  cursor: pointer;
}

#copy-button {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
}

.button .text-2 {
  display: none;
}

.button.animate .text-1 {
  display: none;
}

.button.animate .text-2 {
  display: block;
}

.instructions .number {
  position: relative;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: #ffec80;
  transform: rotate(-12deg);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 1rem;
}

.options-container {
  position: relative;
  display: flex;
  background: #f6f3e4;
}

.fields-container {
  position: relative;
  padding: 1rem;
  padding-top: 4.5rem;
}

.images-container {
  padding: 1rem;
  padding-top: 4.5rem;
  position: relative;
  margin-left: 2rem;
}

.choose-image {
  cursor: pointer;
  width: 9rem;
}
</style>
